<template>
  <div class="myaccount">
    <div class="title-bar">
      <h1>Please Select Your Role</h1>
    </div>
    <div class="roles-content">
      <div class="role-container flex-child">
        <div class="title-bar darker">
          <h2>Select Your Roles</h2>
        </div>
        <div class="role-select-container">
          <v-container fluid>
            <div v-for="availableRole in availableRoles" v-bind:key="availableRole.id">
              <v-checkbox v-model="newRoles" :label="availableRole" :value="availableRole" color="purple darken-3">
              </v-checkbox>
            </div>
          </v-container>
        </div>
        <v-btn class="white--text submit-button" :color="colors.PRIMARY" v-on:click="updateRoles()">Select</v-btn>
      </div>

      <v-layout row justify-center>
        <v-dialog v-model="noRoleSelectedDialog" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">Please select at least one role</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :color="colors.PRIMARY" text @click="noRoleSelectedDialog = false">Dismiss</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </div>
  </div>
</template>

<script>
import colors from "@/colors";
import roleDataAccess from "../../dataAccess/roleDataAccess";
import userDataAccess from "../../dataAccess/userDataAccess";

export default {
  name: "roles",
  data() {
    return {
      colors,
      newRoles: [],
      availableRoles: [],
      user: null,
      noRoleSelectedDialog: false,
      roleDAO: new roleDataAccess(),
      userDAO: new userDataAccess()
    };
  },
  methods: {
    async updateRoles() {
      var updatedRoles = this.newRoles;

      if (updatedRoles.length == 0) {
        return;
      } else {
        try {
          for (var i = 0; i < updatedRoles.length; i++) {
            await this.roleDAO.postUserRole('BreakroomDashboardAdmin');
          }
        }
        catch (err) {
          console.error(err);
          return;
        }
      }

      this.$router.push('/');
    },
    async initAvailableRoles() {
      try {
        this.availableRoles = await this.roleDAO.getAvailableRoles();

      } catch (err) {
        console.log(err);
        return;
      }
    },
    async initUser() {
      try {
        this.user = await this.userDAO.getAccount();
      } catch (err) {
        console.log(err);
        return;
      }
    }
  },
  mounted: async function () {
    await this.initAvailableRoles();
    await this.initUser();
  }
};
</script>

<style>
.role-select-container,
.info-container {
  margin: 20px;
  margin-bottom: 0;
}

.roles-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-child {
  margin: auto 20px;
  margin-top: 20px;
  flex-direction: column;
  flex: 1 1 350px;
}

@media screen and (max-width: 796px) {
  .flex-child {
    margin: 20px 0;
    max-width: 1000px !important;
    width: 100%;
    flex: 1 1 1000px;
  }
}

.role-container,
.personal-info {
  color: #444444;
  box-shadow: 0px 1px 5px #aaaaaa;
  max-width: 400px;
}

.submit-button {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 25px;
}

@media screen and (max-width: 796px) {

  .role-container,
  .personal-info {
    order: -1;
  }
}
</style>